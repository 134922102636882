* {
    outline: none !important;
}

html {
    font-size: 62.5%;
    font-family: Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #262933;
}

body
{
    font-size: 14px;
    line-height: 1.4;
}

html, body, #root
{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

h1, .h1
{
    font-size: 24px;
}

h2, .h2
{
    font-size: 20px;
}

h3, .h3
{
    font-size: 16px;
}

h4, .h4
{
    font-size: 15px;
}

h5, .h5
{
    font-size: 13px;
}

h6, .h6
{
    font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x
{
    z-index: 99;
}

a[role=button]
{
    text-decoration: none;
}


